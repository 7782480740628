@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 7%;
    --foreground: 0 0% 100%;

    --card: 0 0% 100%;
    --card-foreground: 0 0% 3.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 0 0% 3.9%;

    --primary: 350 89% 60%;
    --primary-foreground: 0, 0%, 100%;

    --secondary: 0 0% 15%;
    --secondary-foreground: 0 0% 98%;

    --muted: 0 0% 96.1%;
    --muted-foreground: 0 0% 46.1%;

    --accent: 0 0% 15.1%;
    --accent-foreground: 0 0% 100%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;

    --border: 240 5% 26%;
    --input: 240 5% 26%;
    --ring: 240 5% 26%;
    --input-muted: 351 95% 71%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 0 0% 3.9%;
    --foreground: 0 0% 98%;

    --card: 0 0% 3.9%;
    --card-foreground: 0 0% 98%;

    --popover: 0 0% 3.9%;
    --popover-foreground: 0 0% 98%;

    --primary: 350 89% 60%;
    --primary-foreground: 0 0% 9%;

    --secondary: 0 0% 14.9%;
    --secondary-foreground: 0 0% 98%;

    --muted: 0 0% 14.9%;
    --muted-foreground: 0 0% 63.9%;

    --accent: 0 0% 14.9%;
    --accent-foreground: 0 0% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;

    --border: 0 0% 14.9%;
    --input: 0 0% 14.9%;
    --ring: 0 0% 83.1%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
    font-family: "Poppins", sans-serif;
  }
}

@font-face {
  font-family: "skpukok"; /*Can be any text*/
  src: local("SKPupokSolid400"),
    url("./assets/fonts/SKPupokSolid400.ttf") format("truetype");
}

.skpukok {
  font-family: "skpukok";
}

.text-outline {
  -webkit-text-stroke: 1px white;
}

.double-outline-text {
  position: relative;
  /* -webkit-text-stroke: 2px white; */
  -webkit-text-stroke-width: 2px;
  /* border-radius: var(--radius); */
}

.double-outline-text::before {
  content: "ANYTHING";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  color: white; /* Hide the text fill of the pseudo-element */
  -webkit-text-stroke: 13px white; /* Outer border */
  /* Ensure the pseudo-element uses the same font properties */
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  /* Optional: Adjust positioning if needed */
}

.double-outline-rating-text {
  position: relative;
  /* -webkit-text-stroke: 2px white; */
  -webkit-text-stroke-width: 2px;
  text-align: center;

  /* border-radius: var(--radius); */
}

.double-outline-rating-text::before {
  content: "400000";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  color: white; /* Hide the text fill of the pseudo-element */
  -webkit-text-stroke: 20px white; /* Outer border */
  /* Ensure the pseudo-element uses the same font properties */
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  /* Optional: Adjust positioning if needed */
}

.background-image {
  background-image: url("./../public/assets/tpl01/background.png");
  filter: none;
  object-fit: cover;
  object-position: top;
  position: relative;
  mask-repeat: no-repeat;
  /* z-index: -2; */
}

.rating-bg-image {
  background-image: url("./../public/assets/tpl01/rating-bg.png");
  filter: none;
  position: relative;
  /* z-index: -2; */
}

.btn {
  border: none;
  outline: none;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 12px;
  font-family: "Poppins";
}
.btn::after {
  content: "";
  z-index: -1;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 10px;
}
/* glow */
.btn::before {
  content: "";
  background: linear-gradient(45deg, #f43f5e80, #d946ef80, #f43f5e80);
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 600%;
  z-index: -1;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  filter: blur(8px);
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
  opacity: 1;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.btn:active:after {
  background: transparent;
}

.btn:active {
  font-weight: bold;
}
